import { useDispatch, useSelector } from 'react-redux';
import { useGetFeedbackQuery } from '../app/services/auth';
import { toggleModal } from '../features/ui/uiSlice';
import { ReactComponent as ThumbsDown } from '../images/thumbs-down.svg';
import { ReactComponent as ThumbsUp } from '../images/thumbs-up.svg';
import {
    getBlogPostsByCPAPStatus,
    getBlogPostsBySleepStudyStatus,
    getQuoteIdByCPAPStatus,
    getQuoteIdBySleepStudyStatus,
} from './constants';

const FeedbackThumbs = () => {
    const dispatch = useDispatch();

    return (
        <span className="flex items-center space-x-2 pt-2">
            <button
                className="relative h-9 w-9 rounded-full border bg-white transition hover:border-green-400"
                onClick={(ev) => {
                    dispatch(toggleModal('goodRating'));
                }}>
                <ThumbsUp className="absolute-center" />
            </button>
            <button
                className="relative h-9 w-9 rounded-full border bg-white transition hover:border-green-400"
                onClick={(ev) => {
                    dispatch(toggleModal('badRating'));
                }}>
                <ThumbsDown className="absolute-center" />
            </button>
        </span>
    );
};

export const useGetHomeStatus = () => {
    //redux state values
    const { user } = useSelector((state) => state.auth);
    const { currentSupplies, recommendedSku } = useSelector((state) => state.catalog);
    const { inTreatmentSupportOnly, showFeedbackBox, appointmentWasCancelled } = useSelector(
        (state) => state.ui,
    );

    //queries

    const { data: feedback } = useGetFeedbackQuery();

    const targetStatus =
        user?.patientProfile.cpapOrderStatus !== 'NOT_INITIATED' || recommendedSku
            ? 'cpapOrderStatus'
            : 'homeSleepStudyStatus';

    const blogPosts =
        targetStatus === 'homeSleepStudyStatus'
            ? getBlogPostsBySleepStudyStatus(user?.patientProfile[targetStatus])
            : getBlogPostsByCPAPStatus(user?.patientProfile[targetStatus]);

    let payload = {
        title: 'Learn more about sleep apnea',
        content:
            'We have many resources to help you learn about sleep apnea. Check them out by clicking the link below.',
        actionText: 'Learn more',
        actionDestination: 'resources',
        //represents the advice quote
        quoteId:
            targetStatus === 'homeSleepStudyStatus'
                ? getQuoteIdBySleepStudyStatus(user?.patientProfile[targetStatus])
                : getQuoteIdByCPAPStatus(user?.patientProfile[targetStatus]),
        // represents the post to link to
        quotePostId: blogPosts.quote,
        resourceIds: blogPosts.resources,
    };

    const isCoachingOnlyPath =
        user?.patientProfile?.stopBangResult?.howBroughtToGem === 'STRUGGLE_WITH_CPAP';

    //Determine if user has a prior sleep study to upload or coming is in as coaching only
    const studyToUpload =
        isCoachingOnlyPath ||
        (user?.stopBangResponses?.hasPriorStudy &&
            user?.stopBangResponses?.previouslyDiagnosedWithSleepApnea &&
            !user?.stopBangResponses?.gained20Lbs);

    const uploadedSleepStudyNotApproved =
        user?.patientProfile.mdStatus === 'UPLOADED_SLEEP_STUDY_INCOMPLETE';

    if (feedback?.length === 0 && showFeedbackBox) {
        payload.title = 'WE WANT TO HEAR FROM YOU!';
        payload.content = (
            <>
                How has your experience with GEM been? <br />
                <FeedbackThumbs />
            </>
        );
        payload.actionText = undefined;
    }

    const userNeedsComplianceVisit =
        user?.patientProfile?.treatments.some(
            (treatment) => treatment.isActive && treatment.initialCompliance === 'COMPLIANT',
        ) &&
        !user?.appointments.some(
            (appointment) =>
                appointment.appointmentType === 'Compliance Visit' &&
                ['SCHEDULED', 'OCCURRED'].includes(appointment.appointmentStatus),
        );

    if (targetStatus === 'homeSleepStudyStatus') {
        switch (user?.patientProfile[targetStatus]) {
            case 'NOT_INITIATED':
                if (uploadedSleepStudyNotApproved) {
                    payload.icon = '😴';
                    payload.title = 'New Sleep Study Required';
                    payload.content =
                        'The uploaded sleep study you provided has missing information that we need to move ahead with therapy, a new sleep study is required.';
                    payload.actionText = 'Get at-home sleep study';
                    payload.actionDestination = 'diagnosis/gem-sleep-study';
                    payload.actionSchedulingSku = null;
                } else if (user?.files?.some((file) => file.fileType === 'UPLOADED_SLEEP_STUDY')) {
                    if (
                        user?.patientProfile.mdStatus ===
                        'UPLOADED_SLEEP_STUDY_PENDING_CLINICAL_REVIEW'
                    ) {
                        payload.title = 'Sleep Study Uploaded';
                        payload.content =
                            'Your sleep study has been uploaded and is being reviewed by a GEM SLEEP provider.';
                        payload.actionText = '';
                        break;
                    } else if (user?.patientProfile.mdStatus === 'UPLOADED_SLEEP_STUDY_APPROVED') {
                        payload.title = 'Sleep Study Review Complete!';
                        payload.content =
                            'Your GEM Provider has reviewed your sleep study. Your results are ready for you.';
                        payload.actionText = 'View my Sleep Study Results';
                        payload.actionDestination = '/dashboard/diagnosis';
                        break;
                    }
                } else {
                    payload.title = studyToUpload
                        ? 'Upload your sleep study!'
                        : 'Do you have Sleep Apnea?';
                    payload.content = studyToUpload
                        ? 'A GEM SLEEP Provider will review and provide you a treatment plan!' // TO-DO Needs new copy.
                        : 'Complete a Home Sleep Study test with just one night of sleep!';
                    payload.actionText = studyToUpload ? 'Take me There!' : 'Order Home Sleep Test';
                    payload.actionDestination = studyToUpload
                        ? 'diagnosis/study-upload'
                        : 'diagnosis/gem-sleep-study';
                }

                break;

            case 'STUDY_IN_CART':
                payload.title = 'Do you have Sleep Apnea?';
                payload.content = 'Complete a Home Sleep Study test with just one night of sleep!';
                payload.actionText = 'Order Home Sleep Test';
                payload.actionDestination = 'diagnosis/gem-sleep-study';
                break;

            case 'HST_FULFILLMENT_ORDERED':
            case 'STUDY_ORDERED':
            case 'SERIAL_NUMBER_ADDED':
                payload.title = 'Sleep Study Ordered';
                payload.content =
                    'We have your Home Sleep Study order! Check your email or back here for updates on your order status!';
                payload.actionText = 'Sleep Support Resources';
                payload.actionDestination = 'resources';
                break;

            case 'HST_RECEIVED_IN_OFFICE':
                payload.title = 'Thanks for Creating Your GEM Account!';
                payload.content =
                    'Show your dentist the confirmation screen. They will get you a home sleep test device to take home.';
                payload.actionText = 'Show My Confirmation';
                payload.actionDestination = 'treatment/dentist-confirmation';
                break;
            case 'HST_FULFILLMENT_SHIPPED':
                payload.title = 'Sleep Study in Transit!';
                payload.content =
                    'Great news! Your sleep study order has shipped, check your email for tracking information. In the meantime please review our Home Sleep Study: How to Guide';
                payload.actionText = 'Home Sleep Study: How to Guide';
                payload.actionDestination = 'https://www.mygemsleep.com/hst-how-to-guide';
                break;

            case 'HST_FULFILLMENT_DELIVERED':
                payload.title = 'Time to Test your Sleep';
                payload.content =
                    'Your sleep study has been delivered. It is time to test -- click below to read the instructions on how to set-up your WatchPat device.';
                payload.actionText = 'Home Sleep Study: How to Guide';
                payload.actionDestination = 'https://www.mygemsleep.com/hst-how-to-guide';
                break;

            case 'STUDY_COMPLETED':
                payload.title = 'Sleep Study Under Review!';
                payload.content =
                    "We got your test! Our GEM SLEEP Providers are reviewing your snooze (zzzz's) report, we'll alert you when the results are ready.";
                payload.actionText = 'Sleep Support Resources';
                payload.actionDestination = 'resources';
                break;

            case 'STUDY_REVIEWED':
                payload.title = 'Sleep Study Review Complete!';
                payload.content =
                    'Your GEM Provider has reviewed your sleep study. Your results are ready for you.';
                payload.actionText = 'View my Sleep Study Results';
                payload.actionDestination = '/dashboard/diagnosis';
                break;

            default:
                break;
        }
    } else {
        switch (user?.patientProfile[targetStatus]) {
            case 'HST_COMPLETED_HAS_OSA':
            case 'REFERRAL_CANCELLED':
            case 'SUPPLIES_IN_CART':
            case 'NOT_INITIATED':
                payload.title = 'Time to Take Treatment Action';
                payload.content =
                    "It's time to take action on your personalized treatment recommendation! We will be there every step of the way.";
                payload.actionText = 'Take me There!';
                payload.actionDestination = 'treatment';
                break;

            case 'NEEDS_REFERRAL':
                payload.title = "You're being referred";
                payload.content =
                    "We are preparing your referral to your insurer's CPAP provider. If you decide to instead order a CPAP with GEM you still can.";
                payload.actionText = 'Take me There!';
                payload.actionDestination = 'treatment';
                break;

            case 'REFERRED':
                payload.title = "You've been referred";
                payload.content =
                    "We have sent your referral to your insurer's CPAP provider. If you decide to instead order a CPAP with GEM you still can.";
                payload.actionText = 'Take me There!';
                payload.actionDestination = 'treatment';
                break;

            case 'SUPPLIES_ORDERED':
            case 'FULFILLMENT_ORDER':
                payload.title = 'CPAP Supply Order Check!';
                payload.content =
                    'We are in the process of boxing up your materials for better sleep! Check your email or back here for additional updates on the status of your order.';
                payload.actionText = 'Sleep Support Resources';
                payload.actionDestination = 'resources';
                break;

            case 'SHIPPED':
                payload.title = 'Better Sleep in TRANSIT!';
                payload.content =
                    'Yay! The sleep supplies you ordered are in route to the shipping address provided! Check your email for a tracking number to follow your order.';
                payload.actionText = 'Sleep Support Resources';
                payload.actionDestination = 'resources';
                break;

            case 'DELIVERED':
                payload.title = 'Your Shipment has landed!';
                payload.content =
                    'Time to rip open the box and set-up your new supplies. Your CPAP supply order has been delivered.';
                payload.actionText = 'Adjusting to CPAP';
                payload.actionDestination = 'resources';
                break;

            default:
                break;
        }
    }

    if (user?.appointments.length > 0) {
        const lastAppointment = user.appointments[user.appointments.length - 1];

        if (
            appointmentWasCancelled ||
            (lastAppointment.appointmentStatus === 'CANCELLED' &&
                lastAppointment.appointmentType === 'Initial Consultation') ||
            lastAppointment.appointmentStatus === 'NOSHOW'
        ) {
            payload.title =
                lastAppointment.appointmentStatus === 'CANCELLED' || appointmentWasCancelled
                    ? 'Canceled Appointment'
                    : 'We Missed You!';
            payload.content =
                lastAppointment.appointmentType === 'Initial Consultation'
                    ? 'You have canceled your appointment. Please reschedule your virtual visit. If you ordered a Home Sleep Test, your order is put on hold as a visit is required for shipment.'
                    : 'You have canceled your appointment. Please reschedule your virtual visit.';
            payload.actionText = 'Reschedule Now';
            payload.actionDestination = '/dashboard/appointments/reschedule-appointment';
            payload.actionStateObject = {
                from: 'appointmentRescheduling',
                appointmentToReschedule: lastAppointment,
            };
        }

        return payload;
    }

    if (inTreatmentSupportOnly)
        return {
            title: 'Check out helpful tips and tricks for getting the most out of your treatment.',
            content: null,
            actionText: 'Resources',
            actionDestination: 'resources',
            quoteId: 'dr-mike-howell-5',
            // externalPost: {
            // 	url: 'https://www.mygemsleep.com/dental-sleep-medicine',
            // 	name: 'Dental sleep medicine',
            // 	image: denturesSrc,
            // },
            quotePostId: 'what-is-an-oral-appliance',
            resourceIds: [
                'how-to-care-for-your-oral-appliance',
                'how-to-maintain-your-bite-with-oral-appliance-therapy',
            ],
        };

    if (
        currentSupplies &&
        currentSupplies.some((s) => s.hasOwnProperty('needsReorder') && s.needsReorder)
    )
        return {
            title: (
                <>
                    ⏰<br />
                    Time to Order Supplies
                </>
            ),
            content: 'Your CPAP supplies and mask need to be replaced to work their best!',
            actionText: 'RE-ORDER SUPPLIES',
            actionDestination: 'treatment',
            quoteId: 'dr-mike-howell-3',
            quotePostId: 'caring-for-your-cpap',
            resourceIds: ['what-is-rem-sleep', 'traveling-with-your-cpap-machine'],
        };

    if (userNeedsComplianceVisit)
        return {
            title: 'Time to Schedule your Compliance Visit!',
            content: 'This visit is required by your insurance company for continued payment. ',
            actionText: 'Click to Schedule',
            actionDestination: null,
            actionSchedulingSku: 'COMPLIANCE',
            quoteId: 'dr-mike-howell-3',
            quotePostId: 'caring-for-your-cpap',
            resourceIds: ['what-is-rem-sleep', 'traveling-with-your-cpap-machine'],
        };

    return payload;
};
