import { motion } from 'framer-motion';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useUpdatePatientProfileMutation } from '../../../app/services/auth';
import {
    useGetUserSovasagePartRecommendationsQuery,
    useSelectMaskMutation,
} from '../../../app/services/admin/treatment';
import { useGetUserRecommendedCPAPBundlesQuery } from '../../../app/services/orders';
import { CurrentTreatments } from './TabComponents';
import { formatDate } from '../../../utils/constants';

const TreatmentTab = ({ patient }) => {
    const { data: recommendedBundles } = useGetUserRecommendedCPAPBundlesQuery(patient.id);
    const { data: sovasagePartRecs } = useGetUserSovasagePartRecommendationsQuery(patient.id);

    //Queries
    const [updatePatientProfile, { isSuccess: patientProfileUpdateSuccess }] =
        useUpdatePatientProfileMutation();
    const [selectMask] = useSelectMaskMutation();

    useEffect(() => {
        if (patientProfileUpdateSuccess)
            toast.success('Patient status updated', { theme: 'colored' });
    }, [patientProfileUpdateSuccess]);

    const submitTreatmentRecommendation = async (treatmentRecommendationFields) => {
        await updatePatientProfile({
            id: patient.patientProfile.id,
            userId: patient.id,
            patientFields: {
                ...treatmentRecommendationFields,
            },
        });
    };

    return (
        <motion.div
            className="space-y-4"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}>
            <div className="grid gap-4 lg:grid-cols-2">
                <div className="rounded-xl bg-gray-darker p-5 lg:col-span-2">
                    <p className="mb-3 text-xl font-bold">Current Treatments:</p>
                    <CurrentTreatments
                        submitHandler={submitTreatmentRecommendation}
                        patient={patient}
                    />
                </div>
                <div className="rounded-xl bg-gray-darker p-5">
                    <p className="mb-3 text-xl font-bold">CPAP Mask Recommendations:</p>
                    {recommendedBundles && (
                        <div className="flex flex-col space-y-2">
                            {sovasagePartRecs?.map((rec) => (
                                <ul className="mt-2 ml-4 list-disc" key={rec.id}>
                                    <li className="my-2">
                                        <p className="text-sm">
                                            Created on: {formatDate(rec.createdAt)}
                                        </p>
                                        <p>Mask Name: {rec.maskName}</p>
                                        <p>Part Number: {rec.maskPartNumber}</p>
                                        <p>Fit Score: {rec.fitScore}</p>
                                        <button
                                            className="btn-primary-small"
                                            disabled={rec.selected}
                                            onClick={async () => {
                                                await selectMask({
                                                    userId: patient.id,
                                                    id: rec.id,
                                                });
                                            }}>
                                            {' '}
                                            {rec.selected ? 'Selected' : 'Select'}
                                        </button>
                                    </li>
                                </ul>
                            ))}
                            {(!sovasagePartRecs || sovasagePartRecs.length === 0) && (
                                <p>No Recommendations Found.</p>
                            )}
                        </div>
                    )}
                </div>

                <div className="rounded-xl bg-gray-darker p-5">
                    <p className="mb-3 text-xl font-bold">CPAP Bundle Recommendations:</p>
                    {recommendedBundles && (
                        <div className="flex flex-col space-y-2">
                            {recommendedBundles.map((bundle) => (
                                <ul
                                    className="mt-2 ml-4 list-disc"
                                    key={bundle.recommendedVariation.id}>
                                    <li>
                                        <p className="text-sm">
                                            {bundle.catalogItem.itemData.name}
                                        </p>
                                        <p className="text-xs">
                                            {bundle.recommendedVariation.itemVariationData.name}
                                        </p>
                                    </li>
                                </ul>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </motion.div>
    );
};

export default TreatmentTab;
