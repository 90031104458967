import { BsArrowDown } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { setStatusFilter } from '../../../features/admin/adminSlice';
import { useState } from 'react';
import { differenceInCalendarYears, format, formatDistance } from 'date-fns';
import {
    colorByDateUpdated,
    colorInsuranceStatus,
    determineItemCategoryTags,
    determineOrderCategories,
    estimateStatuses,
    formatDate,
    priorAuthStatuses,
} from '../../../utils/constants';
import DropdownColumnSelection from '../../DropdownColumnSelection/DropdownColumnSelection';
import { useNavigate } from 'react-router-dom';

const sortableColumns = ['ID', 'Date', 'Updated'];

const statusesToColor = [
    'ESTIMATE_NEEDED',
    'ESTIMATE_PENDING',
    'ESTIMATE_WITH_PATIENT',
    'ESTIMATE_PATIENT_APPROVED',
    'PRIOR_AUTH_AFTER_CONSULT',
    'PRIOR_AUTH_NEEDED',
    'PRIOR_AUTH_DENIED',
    'PRIOR_AUTH_PENDING',
    'SUPPLIES_ORDERED',
    'FULFILLMENT_ORDER',
    'SHIPPED',
    'STUDY_ORDERED',
    'HST_FULFILLMENT_ORDERED',
    'HST_FULFILLMENT_SHIPPED',
    'HST_FULFILLMENT_DELIVERED',
    'SERIAL_NUMBER_ADDED',
    'STUDY_COMPLETED',
    'REORDER',
    'HST_RECEIVED_IN_OFFICE',
    'ORDERED',
    'FULFILLMENT_ORDER_PLACED',
];

function LastComplianceDate({ date }) {
    const lessThanAYearOld = differenceInCalendarYears(new Date(), date) < 1;

    return (
        <span className={`${lessThanAYearOld ? 'text-gem-green' : 'text-red'}`}>
            {formatDate(date)}
        </span>
    );
}

const AdminOrderList = ({ orders, patientId, setPage }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { orderBy, orderDesc } = useSelector((state) => state.admin.activeOrderFilters);

    // eslint-disable-next-line
    const [columnsToDisplay, setColumnsToDisplay] = useState([
        'Date',
        'User',
        'Item',
        'Type',
        'Estimate',
        'Prior Auth',
        'ID',
        'Updated',
    ]);

    function determineColumnLayout(order) {
        const { isCpapOrder, isHSTOrder, isSuppliesOrder } = determineOrderCategories(order);

        const itemTypes = order ? determineItemCategoryTags(order) : null;

        const potentialColumns = [
            {
                label: 'Date',
                element: order ? (
                    <p
                        key={`order-date-${order.id}`}
                        className="whitespace-nowrap overflow-hidden text-ellipsis">
                        {format(order.orderDate, 'MM/dd/yyyy')}
                    </p>
                ) : null,
                width: '80px',
            },
            {
                label: 'User',
                element: order ? (
                    <div
                        key={`user-name-and-id-${order.id}`}
                        className={`daisy-tooltip daisy-tooltip-bottom text-left`}
                        data-tip={order.user.firstName + ' ' + order.user.lastName}>
                        <p className="whitespace-nowrap overflow-hidden text-ellipsis">
                            <span className="text-gray">{order.userId}</span>{' '}
                            {order.user.firstName + ' ' + order.user.lastName}
                        </p>
                    </div>
                ) : null,
                width: '150px',
            },
            {
                label: 'Type',
                element: order ? (
                    <p
                        key={`order-type-${order.id}`}
                        className="whitespace-nowrap overflow-hidden text-ellipsis">
                        {order.orderType}
                    </p>
                ) : null,
                width: '90px',
            },
            {
                label: 'Item',
                element: order ? (
                    <p
                        key={`items-in-order-${order.id}`}
                        className="whitespace-nowrap overflow-hidden text-ellipsis">
                        {itemTypes}
                    </p>
                ) : null,
                width: '120px',
            },
            {
                label: 'Updated',
                element: order ? (
                    <p
                        key={`updated-at-${order.id}`}
                        className="whitespace-nowrap overflow-hidden text-ellipsis">
                        {formatDate(order.updatedAt)}
                    </p>
                ) : null,
                width: '80px',
            },
            {
                label: 'Insurance',
                element: order ? (
                    <div key={`insurance-info-${order.id}`} className="flex justify-between pr-4">
                        <div
                            className={`daisy-tooltip daisy-tooltip-bottom text-left max-w-[60%]`}
                            data-tip={order.insurance?.payer.name}>
                            <p className="whitespace-nowrap overflow-hidden text-ellipsis">
                                {order.insurance && order.insurance?.payer.name}
                            </p>
                        </div>

                        <p className="whitespace-nowrap overflow-hidden text-ellipsis">
                            {colorInsuranceStatus(
                                order.insurance && order.insurance?.insuranceStatus,
                            )}
                        </p>
                    </div>
                ) : null,
                width: '1210px',
            },
            {
                label: 'Estimate',
                element: order ? (
                    <div
                        key={`estimate-status-${order.id}`}
                        className={`daisy-tooltip daisy-tooltip-bottom text-left`}
                        data-tip={
                            formatDistance(order.updatedAt, new Date()) + ' since last updated'
                        }>
                        <p
                            className={`whitespace-nowrap overflow-hidden text-ellipsis ${
                                statusesToColor.includes(order.estimateStatus) &&
                                colorByDateUpdated(order.updatedAt)
                            }`}>
                            {order.estimateStatus ? (
                                estimateStatuses.find((item) => item.value === order.estimateStatus)
                                    .label
                            ) : (
                                <span className="text-gray">Not Required</span>
                            )}
                        </p>
                    </div>
                ) : null,
                width: '100px',
            },
            {
                label: 'Prior Auth',
                element: order ? (
                    <div
                        key={`prior-auth-${order.id}`}
                        className={`daisy-tooltip daisy-tooltip-bottom text-left`}
                        data-tip={
                            formatDistance(order.updatedAt, new Date()) + ' since last updated'
                        }>
                        <p
                            className={`whitespace-nowrap overflow-hidden text-ellipsis ${
                                statusesToColor.includes(order.priorAuthStatus) &&
                                colorByDateUpdated(order.updatedAt)
                            }`}>
                            {order.priorAuthStatus ? (
                                priorAuthStatuses.find(
                                    (item) => item.value === order.priorAuthStatus,
                                ).label
                            ) : (
                                <span className="text-gray">Not Indicated</span>
                            )}
                        </p>
                    </div>
                ) : null,
                width: '100px',
            },
            {
                label: 'GEM Status',
                element: order ? (
                    <div
                        key={`gem-status-${order.id}`}
                        className={`daisy-tooltip daisy-tooltip-bottom text-left`}
                        data-tip={
                            formatDistance(order.updatedAt, new Date()) + ' since last updated'
                        }>
                        <p
                            className={`whitespace-nowrap overflow-hidden text-ellipsis ${
                                ((isCpapOrder &&
                                    statusesToColor.includes(
                                        order.user.patientProfile.cpapOrderStatus,
                                    )) ||
                                    (isHSTOrder &&
                                        statusesToColor.includes(
                                            order.user.patientProfile.homeSleepStudyStatus,
                                        )) ||
                                    (isSuppliesOrder &&
                                        statusesToColor.includes(
                                            order.user.patientProfile.supplyOrdersStatus,
                                        ))) &&
                                colorByDateUpdated(order.user.patientProfile.updatedAt)
                            }`}>
                            {isCpapOrder
                                ? order.user.patientProfile.cpapOrderStatus
                                : isHSTOrder
                                  ? order.user.patientProfile.homeSleepStudyStatus
                                  : isSuppliesOrder
                                    ? order.user.patientProfile.supplyOrdersStatus
                                    : null}
                        </p>
                    </div>
                ) : null,
                width: '200px',
            },
            {
                label: 'Order Status',
                element: order ? (
                    <p
                        key={`order-status-${order.id}`}
                        className="whitespace-nowrap overflow-hidden text-ellipsis">
                        {order.orderStatus}
                    </p>
                ) : null,
                width: '100px',
            },
            {
                label: 'Initial Compliance',
                element: order ? (
                    <p
                        key={`order-status-${order.id}`}
                        className="whitespace-nowrap overflow-hidden text-ellipsis">
                        {order.user.patientProfile.treatments.length > 0 &&
                            order.user.patientProfile.treatments[0].initialCompliance}
                    </p>
                ) : null,
                width: '100px',
            },
            {
                label: 'Last Compliance Visit',
                element: order ? (
                    <p
                        key={`order-compliance-${order.id}`}
                        className="whitespace-nowrap overflow-hidden text-ellipsis">
                        {/* Determine last compliance appointment marked as occurred */}
                        {order.user.appointments.find(
                            (appointment) =>
                                appointment.appointmentType === 'Compliance Visit' &&
                                appointment.appointmentStatus === 'OCCURRED',
                        ) && (
                            <LastComplianceDate
                                date={
                                    order.user.appointments.find(
                                        (appointment) =>
                                            appointment.appointmentType === 'Compliance Visit',
                                    ).startAt
                                }
                            />
                        )}
                    </p>
                ) : null,
                width: '40px',
            },
            {
                label: 'ID',
                element: order ? (
                    <p
                        key={`order-id-${order.id}`}
                        className="whitespace-nowrap overflow-hidden text-ellipsis">
                        {order.id}
                    </p>
                ) : null,
                width: '40px',
            },
        ];

        const filteredColumns = potentialColumns.filter((column) =>
            columnsToDisplay.includes(column.label),
        );

        return {
            columnOptions: potentialColumns.map((column) => column.label),
            columnLabels: filteredColumns.map((column) => column.label),
            columns: filteredColumns.map((column) => column.element),
        };
    }

    const { columnLabels, columnOptions } = determineColumnLayout(null);

    return (
        <>
            <DropdownColumnSelection
                options={columnOptions}
                columns={columnsToDisplay}
                setColumns={setColumnsToDisplay}
            />
            <div key={`orders-table`} className="text-sm ">
                <div
                    className={`grid grid-cols-${columnLabels.length} text-left gap-y-4`}
                    key={`orders-table-labels`}>
                    {columnLabels.map((label) => (
                        <p
                            key={`label-${label}`}
                            className={`text-gray flex items-center ${
                                !patientId &&
                                sortableColumns.includes(label) &&
                                'hover:cursor-pointer'
                            }`}
                            onClick={() => {
                                if (!patientId && sortableColumns.includes(label)) {
                                    dispatch(
                                        setStatusFilter(
                                            //If the orderBy is already set to this, adjust the direction
                                            orderBy === label
                                                ? {
                                                      type: 'activeOrderFilters',
                                                      key: 'orderDesc',
                                                      value: !orderDesc,
                                                  }
                                                : //Otherwise change the orderBy
                                                  {
                                                      type: 'activeOrderFilters',
                                                      key: 'orderBy',
                                                      value: label,
                                                  },
                                        ),
                                    );
                                    setPage(0);
                                }
                            }}>
                            <span
                                className={`font-semibold ${
                                    !patientId && orderBy === label
                                        ? 'text-gem-green'
                                        : !patientId && sortableColumns.includes(label)
                                          ? 'hover:text-gem-green'
                                          : null
                                }`}>
                                {label}
                            </span>
                            {!patientId && label === orderBy && (
                                <BsArrowDown
                                    className={`transition duration-200 ease-out-quad left-10 scale-110 text-white ${
                                        orderDesc ? 'rotate-180' : 'rotate-0'
                                    } `}
                                />
                            )}
                        </p>
                    ))}
                    {orders.length > 0 &&
                        orders.map((order) => (
                            <div
                                key={`order-row-${order.id}`}
                                onClick={() => {
                                    navigate(`/admin/patients/${order.userId}/orders/${order.id}`);
                                }}
                                className={`hover:cursor-pointer col-span-${columnLabels.length} grid grid-cols-${columnLabels.length}`}>
                                {determineColumnLayout(order).columns}
                            </div>
                        ))}
                </div>
            </div>
            {orders.length === 0 && (
                <p key="no orders found" className="text-xl font-heading tracking-widest py-2">
                    No orders found.
                </p>
            )}
        </>
    );
};

export default AdminOrderList;
