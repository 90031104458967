import { useSelector } from 'react-redux';

const Reports = () => {
    const url = process.env.REACT_APP_API_BASE_URL ? process.env.REACT_APP_API_BASE_URL : '';
    const { storageToken } = useSelector((state) => state.auth);
    return (
        <div className="space-y-10 py-6">
            <h2 className="mb-10 flex items-center justify-between">
                <span className="font-heading text-5xl">Reports</span>
            </h2>

            <div className="xl:grid xl:grid-cols-12 xl:gap-12">
                <div className="mb-6 space-y-6 pt-4 xl:col-span-3 xl:mb-0">
                    <div className="flex items-center space-x-4 xl:block xl:space-x-0 xl:space-y-5">
                        <a
                            className="btn-primary-small block w-full text-base lg:text-lg"
                            href={`${url}/admin/reports/users?storageToken=${storageToken}`}
                            download
                            target="_blank"
                            rel="noreferrer">
                            Download Current Users CSV
                        </a>
                        <a
                            className="btn-primary-small block w-full text-base lg:text-lg"
                            href={`${url}/admin/reports/history?storageToken=${storageToken}`}
                            download
                            target="_blank"
                            rel="noreferrer">
                            Download Users History CSV
                        </a>
                        <a
                            className="btn-primary-small block w-full text-base lg:text-lg"
                            href={`${url}/admin/reports/insurance?storageToken=${storageToken}`}
                            download
                            target="_blank"
                            rel="noreferrer">
                            Download User Insurance CSV
                        </a>
                        <a
                            className="btn-primary-small block w-full text-base lg:text-lg"
                            href={`${url}/admin/reports/appointments?storageToken=${storageToken}`}
                            download
                            target="_blank"
                            rel="noreferrer">
                            Download User Appointments CSV
                        </a>
                        <a
                            className="btn-primary-small block w-full text-base lg:text-lg"
                            href={`${url}/admin/reports/orders?storageToken=${storageToken}`}
                            download
                            target="_blank"
                            rel="noreferrer">
                            Download Order History CSV
                        </a>
                        <a
                            className="btn-primary-small block w-full text-base lg:text-lg"
                            href={`${url}/admin/reports/order-changes?storageToken=${storageToken}`}
                            download
                            target="_blank"
                            rel="noreferrer">
                            Download Order Changes CSV
                        </a>
                        <a
                            href={`${url}/admin/reports/treatments?storageToken=${storageToken}`}
                            download
                            target="_blank"
                            rel="noreferrer">
                            Download User Treatments CSV
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Reports;
