import { createSlice } from '@reduxjs/toolkit';
import ReactGA from 'react-ga4';
import { Assessment } from '../../utils/assessment';
import { apneaQuestions, getAgeFromDateString, supportOnlyQuestions } from '../../utils/constants';

import { getBMI } from '../../utils/constants';

const initialState = {
    questions: apneaQuestions,
    answers: {},
    currentQuestionIndex: 0,
    showSaveScreen: false,
    showResultScreen: false,
    finalModalOpen: false,
    hasSleepApnea: false,
    previouslyDiagnosed: false,
    skipResultScreen: false,
    showAccountCreationScreen: false,
    bmi: null,
    age: null,
    apneaScore: 0,
    treatmentSupportStatus: null,
    homeSleepStudyStatus: null,
    partnerId: null,
    dentalPracticeId: null,
};

export const assessmentSlice = createSlice({
    name: 'assessment',
    initialState: initialState,
    reducers: {
        setAnswer: (state, { payload: answer }) => {
            const answerKey = state.questions[state.currentQuestionIndex].answerKey;

            if (answerKey === 'previouslyDiagnosedWithSleepApnea') {
                //If the user returns all the way to the previouslyDiagnosed question, reset the assessment
                state.questions = JSON.parse(JSON.stringify(apneaQuestions));

                if (answer) {
                    //If the user was previously diagnosed, skip the result screen
                    state.skipResultScreen = true;

                    //If the user is previously diagnosed, ask if they have gained or lost 20 pounds
                    const gained20LbsIndex = state.questions.findIndex(
                        (q) => q.answerKey === 'gained20Lbs',
                    );

                    state.questions[gained20LbsIndex].skip = false;

                    //If the user is not previously diagnosed, show the result screen
                } else {
                    state.skipResultScreen = false;
                }

                //Determine the index of the previously diagnosed questions
                const howBroughtToGemIndex = state.questions.findIndex(
                    (q) => q.answerKey === 'howBroughtToGem',
                );
                const weCanHelpIndex = state.questions.findIndex(
                    (q) => q.answerKey === 'weCanHelp',
                );
                const hasPriorStudyIndex = state.questions.findIndex(
                    (q) => q.answerKey === 'hasPriorStudy',
                );
                const needNewSleepStudyIndex = state.questions.findIndex(
                    (q) => q.answerKey === 'needNewSleepStudy',
                );
                //And skip those questions if not previously diagnosed
                if (state.questions[howBroughtToGemIndex])
                    state.questions[howBroughtToGemIndex].skip = !answer;
                if (state.questions[weCanHelpIndex]) state.questions[weCanHelpIndex].skip = !answer;
                if (state.questions[hasPriorStudyIndex])
                    state.questions[hasPriorStudyIndex].skip = !answer;
                if (state.questions[needNewSleepStudyIndex])
                    state.questions[needNewSleepStudyIndex].skip = !answer;

                //Set to state whether they were previously diagnosed
                state.previouslyDiagnosed = answer;
            }

            //Determinations when the user tells us how they were brought to GEM
            else if (answerKey === 'howBroughtToGem') {
                //If the user is looking for coaching with their current CPAP, don't skip the extra exclusionary questions
                const highRiskSelectionQuestionIndex = state.questions.findIndex(
                    (q) => q.answerKey === 'highRiskSelections',
                );

                state.questions[highRiskSelectionQuestionIndex + 1].skip =
                    answer !== 'STRUGGLE_WITH_CPAP';

                //If the user needs a new sleep test, skip the hasPriorStudy and needNewSleepStudy questions
                const hasPriorStudyIndex = state.questions.findIndex(
                    (q) => q.answerKey === 'hasPriorStudy',
                );
                if (state.questions[hasPriorStudyIndex])
                    state.questions[hasPriorStudyIndex].skip = answer === 'NEED_NEW_SLEEP_TEST';

                const needNewSleepStudyQuestion =
                    state.questions[
                        state.questions.findIndex((q) => q.answerKey === 'needNewSleepStudy')
                    ];

                if (needNewSleepStudyQuestion)
                    state.questions[
                        state.questions.findIndex((q) => q.answerKey === 'needNewSleepStudy')
                    ].skip = answer === 'NEED_NEW_SLEEP_TEST';

                //Don't show results if the user already has a sleep test
                state.skipResultScreen = answer !== 'NEED_NEW_SLEEP_TEST';
            }

            //If they answer yes to the first question about stopping breathing, skip the second question.
            else if (answerKey === 'doesStopBreathing') {
                const nextQuestion = state.questions[state.currentQuestionIndex + 1];
                if (nextQuestion.answerKey === 'doesStopBreathing')
                    state.questions[state.currentQuestionIndex + 1].skip = answer;
            }

            //If the user has a prior study, skip the need new sleep study question
            else if (answerKey === 'hasPriorStudy') {
                const needNewSleepStudyQuestion =
                    state.questions[
                        state.questions.findIndex((q) => q.answerKey === 'needNewSleepStudy')
                    ];

                if (needNewSleepStudyQuestion) {
                    const needNewSleepQuestIndex = state.questions.findIndex(
                        (q) => q.answerKey === 'needNewSleepStudy',
                    );
                    state.questions[needNewSleepQuestIndex].skip = answer;
                }

                //If the user has no prior study and are not dental referred, calculate their results
                if (answerKey === 'hasPriorStudy' && !answer && !state.dentalPracticeId) {
                    state.skipResultScreen = false;
                }
            }

            //If they have gained or lost 20 pounds, show them the situationChanged question
            else if (
                answerKey === 'gained20Lbs' &&
                state.questions.findIndex((q) => q.answerKey === 'situationChanged')
            ) {
                state.questions[
                    state.questions.findIndex((q) => q.answerKey === 'situationChanged')
                ].skip = !answer;
            }

            //Set the answer to state for calculation and submission
            if (answerKey) state.answers[answerKey] = answer;

            if (typeof answerKey !== 'undefined') {
                switch (answerKey) {
                    case 'dateOfBirth':
                    case 'heightInches':
                    case 'weightLbs':
                        ReactGA.event({
                            category: 'User',
                            action: 'Answered a Question',
                            label: `${answerKey}_true`,
                        });
                        break;
                    case 'highRiskSelections':
                        ReactGA.event({
                            category: 'User',
                            action: 'Answered a Question',
                            label: `${answerKey}_${
                                Array.isArray(answer) ? answer.join(',') : answer
                            }`,
                        });
                        break;
                    default:
                        ReactGA.event({
                            category: 'User',
                            action: 'Answered a Question',
                            label: `${answerKey}_${state.answers[answerKey]}`,
                        });
                }
            }

            //If the user has no high risk selections, proceed to calculate their results
            if (
                answerKey === 'highRiskSelections' &&
                Array.isArray(answer) &&
                answer.length > 0 &&
                answer.indexOf('NONE') < 0
            ) {
                state.showSaveScreen = true;
                // seems repetitive, but this is the only way I can think of
                // to get the save screen to show without a bigger refactor
                state.age = getAgeFromDateString(state.answers.dateOfBirth);
                state.bmi = getBMI(state.answers.weightLbs, state.answers.heightInches);
                state.apneaScore = Assessment.computeStopBangScore(
                    state.answers,
                    state.age,
                    state.bmi,
                );
                state.hasSleepApnea = state.apneaScore >= 3;
                state.showResultScreen = true;
            }
        },
        nextQuestion: (state) => {
            if (
                state.currentQuestionIndex === state.questions.length - 1 &&
                state.treatmentSupportStatus === 'TREATMENT_SUPPORT_ONLY'
            ) {
                state.showSaveScreen = true;
            }

            if (
                !state.finalModalOpen &&
                !state.showSaveScreen &&
                state.treatmentSupportStatus !== 'TREATMENT_SUPPORT_ONLY'
            )
                state.currentQuestionIndex++;

            while (state.questions[state.currentQuestionIndex]?.skip) state.currentQuestionIndex++;

            state.finalModalOpen =
                state.treatmentSupportStatus === 'TREATMENT_SUPPORT_ONLY'
                    ? false
                    : state.currentQuestionIndex === state.questions.length;

            if (state.currentQuestionIndex === state.questions.length) {
                // state.showSaveScreen = true;
            }
        },
        previousQuestion: (state) => {
            state.currentQuestionIndex = state.showSaveScreen
                ? state.questions.length - 1
                : state.currentQuestionIndex - 1;

            while (state.questions[state.currentQuestionIndex].skip) state.currentQuestionIndex--;

            state.showSaveScreen = state.showSaveScreen ? false : state.showSaveScreen;
        },
        setAccountScreen: (state) => {
            state.showAccountCreationScreen = true;
        },
        setResultScreen: (state) => {
            state.age = getAgeFromDateString(state.answers.dateOfBirth);
            state.bmi = getBMI(state.answers.weightLbs, state.answers.heightInches);
            state.apneaScore = Assessment.computeStopBangScore(state.answers, state.age, state.bmi);
            state.hasSleepApnea = state.apneaScore >= 3;
            state.showResultScreen = true;
        },
        setQuestions: (state, { payload: newQuestions }) => {
            state.questions = newQuestions;
        },
        reset: (state) => {
            state.currentQuestionIndex = 0;
            state.showSaveScreen = false;
            state.showResultScreen = false;
            state.hasSleepApnea = false;
            state.showAccountCreationScreen = false;
        },
        setAnythingElse: (state, { payload }) => {
            state.answers.anythingElse = payload;
            state.showSaveScreen = true;
        },
        setFinalModalOpen: (state, { payload }) => {
            state.finalModalOpen = payload;
        },
        setPartnerId: (state, { payload }) => {
            state.partnerId = payload;
        },
        setDentalPracticeId: (state, { payload }) => {
            state.dentalPracticeId = payload;
        },
        setSleepStudyStatus: (state, { payload }) => {
            state.homeSleepStudyStatus = payload;
        },
        setTreatmentSupportStatus: (state, { payload }) => {
            state.treatmentSupportStatus = payload;
            if (payload === 'TREATMENT_SUPPORT_ONLY') {
                state.questions = supportOnlyQuestions;
                state.currentQuestionIndex = 0;
                state.skipResultScreen = true;
                state.showAccountCreationScreen = true;
            }
        },
    },
});

export const {
    setAnswer,
    nextQuestion,
    previousQuestion,
    setAccountScreen,
    setResultScreen,
    reset,
    setFinalModalOpen,
    setAnythingElse,
    setDentalPracticeId,
    setPartnerId,
    setSleepStudyStatus,
    setTreatmentSupportStatus,
    setQuestions,
} = assessmentSlice.actions;

export default assessmentSlice.reducer;

export const selectAssessmentState = (state) => state.assessment;
export const selectCurrentQuestionData = (state) =>
    state.assessment.questions[state.assessment.currentQuestionIndex];
