import { ReactComponent as CartIcon } from '../../images/icons/cart.svg';
import { setSelectedProduct, setSelectedVariation } from '../../features/catalog/catalogSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useInsuranceContext } from '../Insurance';
import { Modal } from '..';
import { useNavigate } from 'react-router';
import { toggleInsurance } from '../../features/cart/cartSlice';
import CpapCoveragePaymentChoice from '../Insurance/CpapCoveragePaymentChoice';

const RecommendedCPAPs = ({ cpaps, user, patientReferredToDME }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    //local state
    const [ready, setReady] = useState(false);
    const [alreadyReferredModalOpen, setAlreadyReferredModalOpen] = useState(false);
    const [paymentChoiceModalOpen, setPaymentChoiceModalOpen] = useState(false);

    //redux state values
    const { selectedVariation } = useSelector((state) => state.catalog);
    const { orderItems, usingInsurance } = useSelector((state) => state.cart);

    //context
    const { insurance, insuranceFetching } = useInsuranceContext();

    useEffect(() => {
        if (!insuranceFetching && user) {
            setReady(true);
        }
    }, [insuranceFetching, user]);

    function handleCPAPBundleClick(cpap) {
        //Set the selected product and variation to the CPAP they clicked on
        dispatch(setSelectedProduct(cpap.catalogItem.id));
        dispatch(setSelectedVariation(cpap.recommendedVariation));

        //If they are referred or needs referral and haven't added a new insurance remind them of this with the already referred modal
        if (
            ['REFERRED', 'NEEDS_REFERRAL'].includes(user?.patientProfile?.cpapOrderStatus) &&
            insurance?.payer?.dmeReferralId
        ) {
            setAlreadyReferredModalOpen(true);
        }
        //If they have a DME payer they should see the CPAP coverage payment choice modal if they haven't already chosen not to use insurance
        else if (insurance && insurance?.payer?.dmeReferralId && usingInsurance !== false) {
            setPaymentChoiceModalOpen(true);
        } else {
            //Otherwise they should navigate to the bundle for ordering
            navigate(`bundle/${cpap.recommendedVariation.id}`);
        }
    }

    const handleContinueClick = () => {
        navigate('/dashboard/treatment/comparison');
    };

    return (
        ready && (
            <>
                <div className="flex flex-col space-y-4 my-5 py-2">
                    {cpaps.map((cpap, index) => {
                        const imgPath = cpap.catalogItem.images?.length
                            ? cpap.catalogItem.images[0]
                            : '';
                        const isReactCPAP = cpap.catalogItem.itemData.name
                            .toLowerCase()
                            .includes('react');
                        return (
                            <div
                                className="relative flex flex-col justify-start w-full bg-white sm:flex-row items-center gap-4 p-8 sm:gap-16"
                                key={`cpap-preview-${index}`}>
                                <div className="relative z-1 sm:w-[240px]">
                                    <span className="absolute -top-4 -right-4 z-9 p-4 text-white rounded-sm bg-[#7168d2] shadow-[0_0px_0px_5px_rgba(0,0,0,0.0.5) font-bold">
                                        {isReactCPAP ? 'Best Value' : 'Most Popular'}
                                    </span>
                                    <img
                                        className="w-9/10 m-0-auto h-auto sm:max-w-[240px]"
                                        alt="cpap-bundle"
                                        src={imgPath}
                                    />
                                </div>
                                <div className="w-full sm:w-3/4 max-w-xl">
                                    <h4 className="mb-3 font-heading text-4xl lg:text-5xl">
                                        {cpap.catalogItem.itemData.name}
                                    </h4>
                                    <p className="mb-4 text-[13px] lg:text-base">
                                        {patientReferredToDME
                                            ? `We’ve submitted a referral for CPAP to ${insurance?.payer.dmeReferral.name}. A representative will connect with you on next steps. If you change your mind and want to order and pay on your own. Click “order now” and we will get things started.`
                                            : cpap.catalogItem.itemData.description}
                                    </p>
                                    <button
                                        onClick={() => {
                                            handleCPAPBundleClick(cpap);
                                        }}
                                        className="btn-primary w-full flex justify-between max-w-xl">
                                        <span className="pt-0.5 sm:pt-0">
                                            {cpap.catalogItem.itemData.variations?.some(
                                                (variation) =>
                                                    orderItems?.some(
                                                        (item) => item.variantId === variation.id,
                                                    ),
                                            )
                                                ? 'In Cart'
                                                : 'Order Now'}
                                        </span>
                                        <CartIcon />
                                    </button>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <Modal
                    showClose={true}
                    isOpen={alreadyReferredModalOpen}
                    closeHandler={() => {
                        setAlreadyReferredModalOpen(false);
                    }}>
                    <div className="space-y-5 text-center">
                        <h2 className="font-heading text-5xl lg:text-7xl">Reminder!</h2>
                        <p className="text-sm lg:text-lg">
                            You have chosen to be referred to {insurance?.payer.dmeReferral?.name}.
                            You may still order with us but you will have to self-pay.
                        </p>
                        <button
                            className="btn-primary w-full"
                            onClick={() => {
                                dispatch(toggleInsurance(false));
                                navigate(`bundle/${selectedVariation.id}`);
                            }}>
                            Got it
                        </button>
                    </div>
                </Modal>
                <CpapCoveragePaymentChoice
                    modalOpen={paymentChoiceModalOpen}
                    setModalOpen={setPaymentChoiceModalOpen}
                    handleContinueClick={handleContinueClick}
                    startOfCPAPFlow={false}
                />
            </>
        )
    );
};

export default RecommendedCPAPs;
